import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../components/layout.css";
import Seo from "../components/seo";

/*Component*/

const PageKusti = () => {
  const data = useStaticQuery(graphql`
  query{
    contentfulProfilePage(profileName: {eq: "Kusti"}) {
      profileName
      profileMedia {
        gatsbyImageData(
          height: 420
          width: 300
          quality: 95
          layout: CONSTRAINED
          formats: AUTO
        )
        description
        contentful_id
        filename
      }
      profileInfo {
        eyes
        hair
        height
        name
        phone
        shoe
        suit
        waist
        email
      }
    }
  }
  `)
  const personal = data.contentfulProfilePage.profileInfo;
  return (
    <Layout>
    <Seo title="Kusti" />
      <div className='model-name'>
      <p>{personal.name}</p>
          <p className="model-info-text">Book me at:</p>
          <p className="model-info-text">{personal.email}</p>
          <p className="model-info-text">{personal.phone}</p>
      </div>
      <div className='cardlist'>
        { data.contentfulProfilePage.profileMedia.map( (item) => (
          <div className='portrait' key={item.contentful_id}>
            <GatsbyImage
              alt='model'
              className='picture'
              image={getImage(item.gatsbyImageData)}
            />
          </div>
        ))}
      </div>
      <div className='model-info'>
          <p className="model-info-text">Name: {personal.name}</p>
          <p className="model-info-text">E-mail: {personal.email}</p>
          <p className="model-info-text">Height: {personal.height}</p>
          <p className="model-info-text">Suit: {personal.suit}</p>
          <p className="model-info-text">Waist: {personal.waist}</p>
          <p className="model-info-text">Shoe: {personal.shoe}</p>
          <p className="model-info-text">Hair: {personal.hair}</p>
          <p className="model-info-text">Eyes: {personal.eyes}</p>
      </div>
    </Layout>
  )
}

export default PageKusti; 